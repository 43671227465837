<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit">
      <template #form>
        <Card class="p-fluid">
          <template #header
            ><h1 class="active">{{ isCreatePassword ? $t('resetpassword.createPasswordTitle') : $t('resetpassword.title') }}</h1></template
          >
          <template #content>
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <PasswordComplexity id="password" v-model="password" toggleMask :placeholder="$t('common.password')" :feedback="true"> </PasswordComplexity>
                </div>
                <field-error :value="v$.password" />
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <Password id="passwordConfirmation" v-model="passwordConfirmation" toggleMask :feedback="false" :placeholder="$t('common.verify_password')" />
                </div>
                <field-error :value="v$.passwordConfirmation" />
              </div>
            </div>
          </template>
          <template #footer>
            <field-error class="form-errmsg p-mb-2 text-center" id="msgerror" :value="errorMessage" />
            <Button type="submit" :label="isCreatePassword ? $t('resetpassword.create_your_password_button') : $t('resetpassword.reset_your_password_button')" :disabled="inProcess"/>

            <div class="d-flex align-items-center p-mt-3" v-if="!isCreatePassword">
              <router-link to="/login">{{ $t('resetpassword.remember_your_password') }}</router-link>
            </div>

            <div class="text-center" v-if="!isCreatePassword">
              <br />
              {{ $t('resetpassword.dont_have_an_account') }}
              <router-link to="/register" class="text-dark">{{ $t('resetpassword.create_one') }}</router-link>
            </div>
          </template>
        </Card>
      </template>
    </login-form>
  </div>
  <CommonDialog ref="dialog"></CommonDialog>
</template>

<script>
import { adminService } from '../services/admin.service';
import { ErrorHelper } from '../helpers/ErrorHelper';
import { useVuelidate } from '@vuelidate/core';
import { sameAs, required, requiredIf, helpers, and } from '@vuelidate/validators';
import LoginForm from '../components/LoginForm.vue';
import PasswordComplexity from '../components/PasswordComplexity.vue';
import FieldError from '../components/FieldError.vue';
import CommonDialog from '../components/CommonDialog.vue';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    LoginForm,
    PasswordComplexity,
    FieldError,
    CommonDialog,
  },
  data() {
    return {
      errorMessage: '',
      password: '',
      passwordConfirmation: '',
      inProcess: false
    };
  },
  computed: {
    isCreatePassword() {
      return this.$route.query?.create === "true" ?? false;
    },
  },
  validations() {
    const validPassword = (value) => this.$store.getters.checkPasswordComplexity(value);
    return {
      password: {
        required: helpers.withMessage(this.$t('passwordComplexity.validationError'),and(
          validPassword,
          requiredIf(function() {
            return this.password !== '';
          })
        )),
      },
      passwordConfirmation: { required, sameAsPassword: sameAs(this.password) },
    };
  },
  methods: {
    handleSubmit() {
      this.errorMessage = '';
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.inProcess = true;
        adminService
          .resetPassword(this.$route.params.usertoken, this.password)
          .then(() => {
            this.showMessage((this.isCreatePassword) ? this.$t('resetpassword.successfullyMessage.forCreate') : this.$t('resetpassword.successfullyMessage.forReset') , () => {
              this.$router.push({ name: 'login' });
            });
          })
          .catch((error) => {
            this.inProcess = false;
            this.errorMessage = ErrorHelper.getErrorMessage(error);
          });
        }
    },
    showMessage(msg, callback) {
      this.$refs.dialog.show({
        title: this.$t('successDialog.title'),
        message: msg,
        icon: 'thin-check',
      }).then(() => {
        if (callback) callback();
      });
    }
  }
};
</script>